<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.xentri_id || !user.account_id">
                        <router-link :to="{ name: 'user-check' }" class="white--text">{{ whatRequiresAttention }}</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>OpenVPN Software</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon :to="{ name: 'user-dashboard' }">
                                <font-awesome-icon :icon="['fas', 'times']" style="font-size: 16px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <!-- <p class="text-overline mb-0 mt-5">Software</p> -->
                            <p class="mb-0 pb-0">
                                How to get the OpenVPN client software:
                            </p>
                            <ul>
                                <li>Android: <a href="https://play.google.com/store/apps/details?id=net.openvpn.openvpn" target="_blank">Google Play</a></li>
                                <li>iOS: <a href="https://apps.apple.com/us/app/openvpn-connect-openvpn-app/id590379981" target="_blank">OpenVPN Connect for iOS</a></li>
                                <li>MacOS: <a href="https://openvpn.net/client-connect-vpn-for-mac-os/" target="_blank">OpenVPN Connect for macOS</a></li>
                                <li>Windows: <a href="https://openvpn.net/community-downloads/" target="_blank">OpenVPN Community Downloads</a> (look for the 64-bit MSI installer) or <a href="https://openvpn.net/client/client-connect-vpn-for-windows/" target="_blank">OpenVPN Connect for Windows</a></li>
                                <li>Linux source: <a href="https://openvpn.net/community-downloads/" target="_blank">OpenVPN Downloads</a> (look for the .tar.gz file)</li>
                                <li>CentOS Linux: <pre>sudo yum install epel-release -y<br>sudo yum update -y<br>sudo yum install openvpn -y</pre></li>
                                <li>Rocky Linux: <pre>sudo dnf install epel-release -y<br>sudo dnf install openvpn -y</pre></li>
                                <li>Ubuntu Linux: <pre>sudo apt-get install openvpn</pre></li>
                            </ul>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="2" class="my-6">
                        <v-card-text>
                            <v-row>
                                <v-col cols="11">
                                    <p class="mb-0 mt-1" style="text-align: end;">
                                        <router-link :to="{ name: 'user-openvpn-config'}">OpenVPN Settings</router-link>
                                    </p>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn icon small color="indigo darken-2" :to="{ name: 'user-openvpn-config'}">
                                            <font-awesome-icon :icon="['fas', 'angle-right']" style="font-size: 16px;" fixed-width/>
                                        </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    computed: {
        ...mapState({
            account: (state) => state.account,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        whatRequiresAttention() {
            const list = [];
            if (!this.user.xentri_id) {
                list.push('profile');
            }
            if (!this.user.account_id) {
                list.push('account');
            }
            if (list.length === 0) {
                return '';
            }
            const nouns = list.join(' and ');
            if (list.length === 1) {
                return `Your ${nouns} requires attention.`;
            }
            return `Your ${nouns} require attention.`;
        },
        downloadURL() {
            return `/api/browser/user/${this.user.id}/download/openvpn/CryptiumVPN.ovpn`;
        },
        linuxFileLocation() {
            return '/etc/openvpn/client';
        },
        windowsFileLocation() {
            return 'C:\\Users\\yourname\\OpenVPN\\config';
        },
    },
    methods: {
        settings() {
            this.$router.push({ name: 'user-settings' });
        },
        async createOpenvpnPassword() {
            try {
                const response = await this.$client.user(this.user.id).user.createOpenvpnPassword();
                console.log(`Got response from createOpenvpnPassword: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log(`Failed to create openvpn password: ${err.message}`);
            }
        },
    },
};
</script>
